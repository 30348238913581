import React, { useState, useEffect } from 'react';
import './FilterPill.scss';
import Form from 'react-bootstrap/Form';
import Button from '../../../node_modules/react-bootstrap/esm/Button';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';


const FilterPill = ({ Appliedfilter, onUpdate }) => {

    const [Appliedfiltersdata, setAppliedfiltersdata] = useState([]);
    const [checkedval, setcheckedval] = useState([]);
    const [allFiltersArray, setallFiltersArray] = useState([]);
    const [checkboxFiltersArray, setcheckboxFiltersArray] = useState([]);



    useEffect(() => {

        const filtersArray = Object.entries(Appliedfilter.Allfilters)
            .filter(([key, value]) => value && value !== '' && value !== '0' && typeof value !== 'boolean' && value.length !== 0)
            .map(([key, value]) => ({ key, value }));

        setAppliedfiltersdata(filtersArray);
       
        const allfiltersary = Object.entries(Appliedfilter.Allfilters)
            .filter(([key, value]) => value && value !== '' && value !== '0' && typeof value !== 'boolean')
            .map(([key, value]) => ({ key, value }));
        if (allfiltersary.length > 0) {
            setallFiltersArray(allfiltersary);
        }
     

        const checkboxones = Object.entries(Appliedfilter.checkboxfilters)
            .filter(([key, value]) => value && value !== '' && value !== '0' && typeof value !== 'boolean' && value.length !== 0)
            .map(([key, value]) => ({ key, value }));
        if (checkboxones.length > 0) {
            setcheckboxFiltersArray(checkboxones);
        }

       

    }, [Appliedfilter, checkedval]);



    const renderTooltip = (dataset) => {

        let tagValue = "";
        
      
        if (dataset === 'Profile' || dataset === 'Status' || dataset === 'AssignedTo') {
            
            if (dataset === 'Status' ) {
                const tagItem = checkboxFiltersArray.find(item => item.key === dataset); 
                if (tagItem) {
                    tagValue = tagItem.value;
                }
            } 
            else {
                const tag = checkboxFiltersArray.find(item => item.key === dataset);
                if (tag) {
                    tagValue = tag.value;
                }
            }
        }
        else  {
            const tag = allFiltersArray.find(item => item.key === dataset);
            if (tag) {
                tagValue = tag.value;
            }

        }

        return (
            dataset !== 'Status' ? (
                <Tooltip className="custom-tooltip">
                    {tagValue}
                </Tooltip>
            ) : (
                <Tooltip className="custom-tooltip">
                   
                    {tagValue.map(function (tooltip, i) {
                        return <p className="mb-0" obj={tooltip} key={i}>{tooltip}</p>;
                    })}
                </Tooltip>
            )
        );
    }




    const filterSelected = (tagname) => {

        if (checkedval.includes(tagname)) {
            // If tagname is already in the array, remove it
            setcheckedval(prevTags => prevTags.filter(tag => tag !== tagname));
        } else {
            // Otherwise, add it to the array
            setcheckedval(prevTags => [...prevTags, tagname]);
        }

    }

    const Deletetagandfilter = () => {
        const updatedFilter = { ...Appliedfilter.Allfilters };

        // Update values for the keys present in checkedval to empty strings

       
        checkedval.forEach(key => {
            if (updatedFilter.hasOwnProperty(key)) {
                updatedFilter[key] = '';
            }
        });
      


        onUpdate(updatedFilter);
        const updatedCheckedVal = checkedval.filter(key => updatedFilter[key] !== '');
        setcheckedval(updatedCheckedVal);
      

    }


    return (
        <div className="applied-filters d-flex align-items-center">
            <p className="mb-0">Applied Filters:</p>
            {Appliedfiltersdata.map((filter) => (

                <div className=""  >
                    <OverlayTrigger
                        key={filter.key}
                        placement="bottom"
                        overlay={renderTooltip(filter.key)}
                       
                    >
                        <div className="filter-strip d-flex">


                            <div key={filter.key} className="filter-pill d-flex ms-1 text-white">
                                <Form>
                                    {['checkbox'].map((type) => (
                                        <div key={`inline-${type}`} >
                                            <Form.Check
                                                inline

                                                name="filterPill"
                                                type={type}
                                                id={`inline-${type}-2`}

                                                onChange={(e) => { filterSelected(filter.key) }}
                                            />
                                        </div>
                                    ))}
                                </Form>
                                <div className="d-flex align-items-center">
                                    <span className="filter-key">{filter.key}</span>
                                  
                                </div>
                            </div>

                        </div>
                    </OverlayTrigger>
                </div>


            ))}
            {checkedval.length > 0 && < Button variant="link" className={`ms-2 ${checkedval.length <= 0 ? "d-none" : "d-block"}`} onClick={Deletetagandfilter}>Remove filters</Button>}
        </div>
    );
}

export default FilterPill;