import React, { useState, useEffect, useMemo } from "react";
import { Modal, Button, FormControl, Table, Pagination } from "react-bootstrap";
import { apiUrl } from '../../config.js';
import { useCookies } from "react-cookie";
import "./StaffSearchstyles.css";
import LoadingSpinner from '../Loading-Component/Loading';

const StaffSearch = ({ showModal, handleClose }) => {
    const [show, setShow] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [key, setKey] = useState("currentstaff");
    const [currentstaff, setCurrentstaff] = useState([]);
    const [currentStaffPage, setCurrentStaffPage] = useState([]);
    const [cookies, setCookie] = useCookies(["jwt", "coid", "loggedInTime"]);
    const jwt = cookies['jwt'];
    const [selectedRows, setSelectedRows] = useState([]);
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [sortDirection, setSortDirection] = useState(null);
    const [triggerSearch, setTriggerSearch] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const ITEMS_PER_PAGE = 50;
    const [loading, setLoading] = useState(false);
    const UserId = cookies['userid'];




    const handleSearch = (event) => {
        event.preventDefault();
        setSearchTerm(event.target.value);
        setCurrentPage(1); // Reset pagination
        setTriggerSearch(true);
    };
    const handleSave = () => {
        // Pass selectedRows back to parent component
        handleClose(selectedRows);
        if (true) {
            setSelectedRows([]);
        }
        setSearchTerm("");

    };

    const filteredAndSortedStaff = useMemo(() => {
        if (!triggerSearch) {
            return currentstaff; // return the initial list if button hasn't been clicked
        }

        let filtered = currentstaff.filter((item) =>
            Object.keys(item).some((key) =>
                key !== 'tags' && item[key] ? item[key].toString().toLowerCase().includes(searchTerm.toLowerCase()) : false
            )
        );


        // Sort
        if (sortField !== null) {
            let sorted = [...filtered];
            sorted.sort((a, b) => {
                if (a[sortField] < b[sortField]) {
                    return sortOrder ? -1 : 1;
                }
                if (a[sortField] > b[sortField]) {
                    return sortOrder ? 1 : -1;
                }
                return 0;
            });
            return sorted;
        }



        return filtered;
    }, [currentstaff, searchTerm, sortField, triggerSearch, sortOrder]);
    const handleSort = (field) => {
        if (sortField === field) {
            setSortOrder(!sortOrder);
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortField(field);
            setSortOrder(true);
        }
    };

    async function fetchCurrentStaff() {
        setSearchTerm("");

        try {
            setLoading(true);


            const response = await fetch(`${apiUrl}/StaffSearch/GetCurrentStaffDetails/${key}/${UserId}`, {
                method: 'GET',
                headers: { "Authorization": `bearer ${jwt}` },

            });



            if (!response.ok) {
                window.alert('Failed to fetch current staff details');
                setLoading(false);
            }

            const currentStaff = await response.json();

            if (currentStaff.errorMessage) {           
                window.alert(currentStaff.errorMessage);
                setLoading(false);
                return; 
            }

            if (currentStaff.records) {
                setCurrentstaff(currentStaff.records);
            } 
          
            setLoading(false);

        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    const handlesearchbutton = async () => {

        if (searchTerm == '') {
            fetchCurrentStaff();
        }
        else {

            try {
                setLoading(true);

                const response = await fetch(`${apiUrl}/StaffSearch/GetSearchedStaffDetails/${searchTerm}/${UserId}`, {
                    method: 'GET',
                    headers: { "Authorization": `bearer ${jwt}` },

                });

                if (!response.ok) {
                    window.alert('Failed to fetch current staff details');
                    setLoading(false);
                }

                const currentStaff = await response.json();

                if (currentStaff.records.length === 0) {
                    window.alert("No Results found");
                }

                if (currentStaff.errorMessage) {
                    window.alert(currentStaff.errorMessage);
                    setLoading(false);
                    return;
                }

                if (currentStaff.records) {
                    setCurrentstaff(currentStaff.records);
                } 
           

                setLoading(false);

            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        }

    };
    const handleRowClick = (rowData) => {
        // Toggle row selection
        const isSelected = selectedRows.includes(rowData);
        if (isSelected) {
            setSelectedRows(selectedRows.filter(row => row !== rowData));
        } else {
            setSelectedRows([...selectedRows, rowData]);
        }
    };
    useEffect(() => {


        if (showModal) {
            fetchCurrentStaff();
        }

    }, [showModal]);


    const paginatedItems = useMemo(() => {
        const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
        return filteredAndSortedStaff.slice(startIndex, startIndex + ITEMS_PER_PAGE);
    }, [filteredAndSortedStaff, currentPage]);

    const maxPage = 5; // Maximum number of pages to show at once
    const totalItems = filteredAndSortedStaff.length;
    const totalPages = Math.ceil(totalItems / ITEMS_PER_PAGE);
    const visiblePages = Math.min(maxPage, totalPages);

    const firstVisiblePageNumber = Math.max(1, currentPage - Math.floor(visiblePages / 2));
    const lastVisiblePageNumber = Math.min(totalPages, firstVisiblePageNumber + visiblePages - 1);

    const shouldShowFirstEllipsis = firstVisiblePageNumber > 2;
    const shouldShowLastEllipsis = lastVisiblePageNumber < totalPages - 1;
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = Math.min(startIndex + ITEMS_PER_PAGE, totalItems);
    return (
        <>
            {loading &&
                <LoadingSpinner loading={loading} />

            }


            <Modal dialogClassName="staff-searchmodal" show={showModal} onHide={handleClose} style={{ display: 'inline-table' }}>
                <Modal.Header closeButton>
                    <Modal.Title>Staff Search</Modal.Title>
                </Modal.Header>
                <Modal.Body className="staffsearch-body">
                    <section>

                        <div className="d-flex gap-2 mb-3 justify-content-center">
                            <FormControl
                                type="text"
                                className="staff-searchinput"
                                placeholder="Search.."
                                value={searchTerm}
                                onChange={handleSearch}
                            />
                            <Button onClick={handlesearchbutton}>Search</Button>
                        </div>
                        <div className="d-flex">
                            <section className="fixed-width-table" >
                                <Table striped  >
                                    <thead>
                                        <tr>
                                            <th>Select</th>

                                            <th onClick={() => handleSort('id')}>
                                                ID {sortField === 'id' && (sortDirection === 'asc' ? '↑' : '↓')}
                                            </th>
                                            <th onClick={() => handleSort('lastName')}>
                                                Last Name {sortField === 'lastName' && (sortDirection === 'asc' ? '↑' : '↓')}
                                            </th>
                                            <th onClick={() => handleSort('firstName')}>
                                                First Name {sortField === 'firstName' && (sortDirection === 'asc' ? '↑' : '↓')}
                                            </th>
                                            <th onClick={() => handleSort('middleName')}>
                                                Middle Name {sortField === 'middleName' && (sortDirection === 'asc' ? '↑' : '↓')}
                                            </th>
                                            <th onClick={() => handleSort('nickname')}>
                                                Known As {sortField === 'nickname' && (sortDirection === 'asc' ? '↑' : '↓')}
                                            </th>
                                            <th onClick={() => handleSort('mobileNo')}>
                                                Mobile Number {sortField === 'mobileNo' && (sortDirection === 'asc' ? '↑' : '↓')}
                                            </th>
                                            <th onClick={() => handleSort('postTown')}>
                                                Home Town {sortField === 'postTown' && (sortDirection === 'asc' ? '↑' : '↓')}
                                            </th>
                                            <th onClick={() => handleSort('dateLastWorked')}>
                                                Date Last Worked {sortField === 'dateLastWorked' && (sortDirection === 'asc' ? '↑' : '↓')}
                                            </th>
                                            <th onClick={() => handleSort('department')}>
                                                Department {sortField === 'department' && (sortDirection === 'asc' ? '↑' : '↓')}
                                            </th>
                                            <th onClick={() => handleSort('dob')}>
                                                Date of Birth {sortField === 'dob' && (sortDirection === 'asc' ? '↑' : '↓')}
                                            </th>
                                            <th onClick={() => handleSort('areaManager')}>
                                                Area Manager {sortField === 'areaManager' && (sortDirection === 'asc' ? '↑' : '↓')}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paginatedItems.map((item, idx) => (
                                            <tr key={idx} onClick={() => handleRowClick(item)}>
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        checked={selectedRows.includes(item)}
                                                        onChange={() => handleRowClick(item)}
                                                    />

                                                </td>
                                                <td>{item.id}</td>
                                                <td>{item.lastName}</td>
                                                <td>{item.firstName}</td>
                                                <td>{item.middleName}</td>
                                                <td>{item.nickname}</td>
                                                <td>{item.mobileNo}</td>
                                                <td>{item.postTown}</td>
                                                <td>{item.dateLastWorked}</td>
                                                <td>{item.workbase}</td>
                                                <td>{item.dob}</td>
                                                <td>{item.areaManagerName}</td>


                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>

                            </section>
                        </div>

                        <div>

                            <p style={{ textAlign: 'center' }}>Showing {startIndex + 1} to {endIndex} of {totalItems} entries</p>

                            <Pagination>
                                <Pagination.Item disabled={currentPage === 1} onClick={() => setCurrentPage(currentPage - 1)}>Previous</Pagination.Item>
                                <Pagination.Item active={currentPage === 1} onClick={() => setCurrentPage(1)}>{1}</Pagination.Item>

                                {shouldShowFirstEllipsis && <Pagination.Ellipsis />}
                                {Array.from({ length: lastVisiblePageNumber - firstVisiblePageNumber + 1 }, (_, i) => firstVisiblePageNumber + i).map(pageNumber => {
                                    if (pageNumber !== 1 && pageNumber !== totalPages) {
                                        return (
                                            <Pagination.Item key={pageNumber} active={pageNumber === currentPage} onClick={() => setCurrentPage(pageNumber)}>
                                                {pageNumber}
                                            </Pagination.Item>
                                        )
                                    }
                                    return null;
                                })}
                                {shouldShowLastEllipsis && <Pagination.Ellipsis />}
                                {totalPages > 1 && < Pagination.Item active={currentPage === totalPages} onClick={() => setCurrentPage(totalPages)}>{totalPages}</Pagination.Item>}
                                <Pagination.Item disabled={currentPage === totalPages} onClick={() => setCurrentPage(currentPage + 1)}>Next</Pagination.Item>

                            </Pagination>
                        </div>


                    </section>
                </Modal.Body>

                <Modal.Footer className="buttoncenter">
                    <Button variant="secondary" onClick={(e) => handleSave(e.target.value)}>
                        OK
                    </Button>
                
                    <Modal.Footer>
                        <Button variant="secondary" onClick={(e) => handleClose(e.target.value)}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal.Footer>
            </Modal>

        </>

    );
};

export default StaffSearch;
