import React from 'react';
import { useState, useEffect, useCallback, useRef } from 'react';
import { useTable, useFilters, useGlobalFilter, useRowSelect, usePagination, useSortBy } from 'react-table';
import { GlobalFilter, DefaultFilterForColumn } from "./CustomFilter";
import { Container, Dropdown, Toast } from 'react-bootstrap';
import { BsArrowDown, BsArrowUp, BsArrowDownUp, BsLaptop } from "react-icons/bs";
import { VscGraph } from "react-icons/vsc";
import { RiUserSearchFill } from "react-icons/ri"
import { useCookies } from "react-cookie";
import { apiUrl } from '../../config.js';
import { intialfiltervalues, intialtwofiltervalues } from './FilterInitialstate';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import { BsFillFunnelFill } from "react-icons/bs";
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import DropdownMenu from '../../../node_modules/react-bootstrap/esm/DropdownMenu';
import Modal from 'react-bootstrap/Modal';
import DropdownButton from '../../../node_modules/react-bootstrap/esm/DropdownButton';
import Col, { useCol } from '../../../node_modules/react-bootstrap/esm/Col';
import FilterPill from '../FilterPill-Component/FilterPill';

// Our table component
export function Table({ columns, data, skipPageReset, updateMyData, Topfilter, Companyfilter, Savedfilter, Filtervalues, onGlobalFilterChange, Externalvalue, exportingacess, handeladvanceserch, handelonupdate, combinedfilter }) {
    const [selectedOption, setSelectedOption] = useState([]);
    const [Staffids, setStaffids] = useState([]);
    const [showColumnsPopup, setShowColumnsPopup] = useState(false);
    const [hiddenColumns, setHiddenColumns] = useState([]);
    const [visibleColumns, setVisibleColumns] = useState(columns.map((col) => col.id));
    const [columnshow, setcolumnshow] = useState({});
    const [cookies] = useCookies(["jwt"]);
    const jwt = cookies.jwt;
    const [showToast, setShowToast] = useState(false);
    const [ToastMessage, setToastMessage] = useState('');
    const [filtersToapply, setfiltersToapply] = useState(intialfiltervalues);
    const [filteraccordion, setfilteraccordion] = useState(false);
    const scrollrefernce = useRef(null);
    const UserId = cookies['userid'];


    useEffect(() => {

        //while applying filter from saved filter it we are captilizing the names for filter,
        //here we are converting into same values as intitalfiltervalues
        const initialFilterValuesKeys = Object.keys(filtersToapply);
        const lowerCaseAnotherObject = {};
        for (let key in Filtervalues) {
            lowerCaseAnotherObject[key.toLowerCase()] = Filtervalues[key];
        }
        const matchingKeyValuePairs = {};
        initialFilterValuesKeys.forEach(key => {
            let lowerCaseKey = key.toLowerCase();
            if (lowerCaseAnotherObject.hasOwnProperty(lowerCaseKey)) {
                matchingKeyValuePairs[key] = lowerCaseAnotherObject[lowerCaseKey];
            }
        });
        const falseProperties = Object.entries(matchingKeyValuePairs)
            .filter(([key, value]) => value === false)
            .map(([key, value]) => key);
        //const trueProperties = Object.entries(Filtervalues)
        //    .filter(([key, value]) => value === true)
        //    .map(([key, value]) => key);
        setHiddenColumns([...falseProperties, falseProperties]);

    }, [Filtervalues]);


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        state,
        toggleAllRowsSelected,
        prepareRow,
        allColumns,
        setGlobalFilter,
        preGlobalFilteredRows,
        selectedFlatRows,
        setAllFilters,
        toggleRowSelected,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
        rows,


    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pagesize: 10, hiddenColumns: hiddenColumns },
            defaultColumn: { Filter: DefaultFilterForColumn },
            autoResetPage: !skipPageReset,
            updateMyData,
            Topfilter,
            Filtervalues


        },

        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
        useRowSelect,
        hooks => {
            hooks.visibleColumns.push(columns => [

                {
                    id: 'selection',
                    accessor: 'select',
                    Header: ({ getToggleAllRowsSelectedProps }) => {
                        const toggleProps = getToggleAllRowsSelectedProps();
                        return (
                            <div>
                                <input
                                    type="checkbox"
                                    checked={toggleProps.checked}
                                    indeterminate={toggleProps.indeterminate}
                                    onChange={(e) => {
                                        toggleAllRowsSelected(!toggleProps.checked);
                                        handleAllRowsSelected(e.target.checked);
                                    }}
                                />
                            </div>
                        );
                    },
                    Cell: ({ row }) => {
                        const isChecked = row.isSelected;
                        return (
                            <div>
                                <input
                                    type="checkbox"
                                    checked={isChecked}
                                    onChange={(e) => {
                                        toggleRowSelected(row.id);
                                        handleRowSelected(row, e.target.checked);
                                    }}
                                />
                            </div>
                        );
                    }
                },
                ...columns,
            ])
        }

    );




    useEffect(() => {
        if (scrollrefernce.current) {
            scrollrefernce.current.scrollTop = 0;
        }
    }, [pageIndex]);


    const handleRowSelected = (row, isChecked) => {
        if (isChecked) {
            setStaffids((prevStaffids) => [...prevStaffids, row.original.staffID]);
        } else {
            setStaffids((prevStaffids) =>
                prevStaffids.filter((staffID) => staffID !== row.original.staffID)
            );
        }
    };
    const handleAllRowsSelected = (isSelected) => {
        if (isSelected) {
            setStaffids(rows.map((row) => row.original.staffID));
        } else {
            setStaffids([]);
        }
    };

    const handelactions = (event) => {
        const selectedOption = event;
        setSelectedOption(selectedOption || undefined);
        if (selectedOption === 'opt5') {
            handeladvanceserch();
            return;
        }

        if (Staffids.length === 0) {
            window.alert('Please select the check box in the table');
        }
        else if (Staffids !== null || Staffids === []) {
            switch (selectedOption) {
                case 'opt1':
                    chaseinfo(Staffids);
                    break;
                case 'opt2':
                    chaserefences(Staffids);
                    break;
                case 'opt3':
                    sendmessage(Staffids);
                    break;
                case 'opt4':
                    generateexcel(Staffids);
                    break;
                default:
                    console.log('invalid');
                    break;
            }
        }
        
    };
    /* 
    
    Controles the darkmode toggle switch in the filter bar area and stylesheet toggling for custom-table 
    Send this to database darkmode is false lightmode is true and store
    
    */
    const [switchState, setSwitchState] = useState(true); /* Default value from database to set users default mode */
    const darkMode = (e) => {
        setSwitchState(!switchState)
        console.log(switchState)
    }
    const [show, setShow] = useState(false);
    const toggleShow = () => {
        console.log("it worked")
        this.setShow((prev) => this.setShow(!prev));
    }
    return (
        <>


            <>

                <Toast
                    onClose={() => setShowToast(false)}
                    show={showToast}
                    delay={3000}
                    autohide
                    style={{
                        position: 'fixed',
                        top: 20,
                        right: 20,
                        minWidth: 200,
                        backgroundColor: 'rgba(76, 175, 80, 0.9)',

                    }}
                >
                    <Toast.Header>
                        <strong className="mr-auto">Success</strong>
                    </Toast.Header>
                    <Toast.Body >{ToastMessage}</Toast.Body>
                </Toast>
            </>
            
            <div className="w-100 d-flex align-items-center justify-content-between filterbar-container">
                <FilterPill Appliedfilter={combinedfilter} onUpdate={handelonupdate } />
                <div className="search-container d-flex align-items-center">
                    <div className="live-search">
                        <GlobalFilter
                            Externalvalue={Externalvalue}
                            onGlobalFilterChange={onGlobalFilterChange}
                            preGlobalFilteredRows={preGlobalFilteredRows}
                            globalFilter={state.globalFilter}
                            setGlobalFilter={setGlobalFilter}
                        />
                    </div>
                    <div className="actions d-flex align-items-center">
                        <Dropdown title="Actions" onSelect={handelactions} autoClose="outside">
                            <Dropdown.Toggle variant="light" id="dropdown-split-basic" className="mr-2">
                                Actions
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item eventKey="opt1">Chase outstanding info from staff
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="opt2">Chase outstanding references
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="opt3">Send message to selected
                                </Dropdown.Item>
                                {exportingacess && <Dropdown.Item eventKey="opt4">Export to excel
                                </Dropdown.Item>}
                                <Dropdown.Item eventKey="opt5">Advanced staff search
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="saved-filters mr-1 d-flex align-items-center">
                        <Savedfilter />
                    </div>
                    <div className="filters-dropdown mr-1">
                        <Dropdown title="Filters" autoClose="outside" show={show} onToggle={(isOpen) => setShow(isOpen)}>
                            <Dropdown.Toggle variant="light" id="dropdown-split-basic" className="mr-2 filters-nav">
                                <BsFillFunnelFill />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Topfilter />
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <Form className="darkmode-toggle">
                        <Form.Check
                            type="switch"
                            id="dark-mode"
                            label={switchState === false ? "Dark table" : "Light table"}
                            defaultChecked={switchState} /* Default value from database */
                            onChange={darkMode}
                        />
                    </Form>
                </div>

                </div>

            <div className="container-table">
                <div className={`custom-table ${switchState === false ? "theme--light" : "theme--dark"}`}>

                    <div className={` d-flex ${filteraccordion ? "gap-5 ml-5" : "gap-3 mb-4"} `} >

                        <div className={`filter-container ${filteraccordion ? "filter-container-laptop" : ""}`}>
                            <Companyfilter />
                        </div>

                        <div className={"Filter_Header"}
                            style={filteraccordion ? { left: 'unset', top: '4em' } : {}}>
                   
                        </div>

                    </div>

                    <div className={`custom-table-head ${data.length > 0 ? "custom-table-body " : "d-flex custom-table-body-empty"}`} ref={scrollrefernce}
                        style={filteraccordion ? { height: '60vh' } : { height: '72vh' }}>

                        <table {...getTableProps()}>
                            <thead className="main-tableheader">
                                {headerGroups.map((headerGroup) => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => {
                                            const nonsortablecolumns = ['selection']
                                            const isSortable = !nonsortablecolumns.includes(column.id);
                                            return (
                                                <th {...column.getHeaderProps(isSortable ? column.getSortByToggleProps() : {})} >
                                                    <div className="header-svg-container">
                                                        {column.render('Header')}

                                                        {isSortable
                                                            &&
                                                            (column.isSorted
                                                                ? column.isSortedDesc
                                                                    ? <BsArrowDown />
                                                                    : <BsArrowUp />
                                                                : <BsArrowDownUp />
                                                            )
                                                        }

                                                    </div>
                                                </th>
                                            );
                                        })}
                                    </tr>
                                ))}
                            </thead>
                            {data.length > 0 ? (
                                <tbody {...getTableBodyProps()}>
                                    {page.map((row, i) => {
                                        prepareRow(row);

                                        return (
                                            <tr {...row.getRowProps()}>

                                                {row.cells.map((cell) => {
                                                    const header = cell.column.id;
                                                    return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                                                })}
                                            </tr>
                                        );
                                    })}
                                </tbody>

                            ) : (
                                <div className="custom-message">
                                        <div className="d-flex align-items-center justify-content-center">
                                        <div>
                                            <BsLaptop className="custom-icon" />
                                            <VscGraph className="custom-icon mx-3" />
                                            <RiUserSearchFill className="custom-icon" />
                                        </div>

                                    </div>
                                    <h3><strong>We searched everywhere</strong></h3>
                                    <p>But we couldn't find any applicant that match your current
                                        filter criteria.</p>
                                </div>
                            )}
                        </table>
                    </div>


                </div>
            </div>

            <div className="pagination">
                <button className="pagination-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {'<<'}
                </button>{' '}
                <button className="pagination-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {'<'}
                </button>{' '}
                <button className="pagination-button" onClick={() => nextPage()} disabled={!canNextPage}>
                    {'>'}
                </button>{' '}
                <button className="pagination-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                    {'>>'}
                </button>{' '}
                <span>
                    Page{' '}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>{' '}
                </span>
                <span>
                    | Go to page:{' '}
                    <input
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            gotoPage(page)
                        }}
                        style={{ width: '100px' }}
                    />
                </span>{' '}
                <select
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value))
                    }}
                >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
            </div>
        </>
    );


    async function chaseinfo(Sids) {
        selectedFlatRows.map((v) => {
            toggleRowSelected(v.id, false);
        });
        try {
            const response = await fetch(`${apiUrl}/screeningsummary/ChaseStaffForInfo/${UserId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `bearer ${jwt}`,
                },
                body: JSON.stringify(Sids.join(',')),
            });
            if (response.ok && response.status === 200) {
                setToastMessage('Request sent successfully');
            } else {
                const results = await response.json();
                window.alert(results.value);
                setToastMessage('Error while sending the request');
            }
        } catch (error) {
            console.error('Error:', error);
            setToastMessage('An error occurred');
        }
        finally {
            setShowToast(true);
            setStaffids([]);
        }
    }






    async function chaserefences(Sids) {
        selectedFlatRows.map(v => {
            toggleRowSelected(v.id, false)
        });
        try {
            const response = await fetch(`${apiUrl}/screeningsummary/Chasereference/${UserId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `bearer ${jwt}`,
                },
                body: JSON.stringify(Sids.join(","))
            });
            if (response.ok && response.status === 200) {
                setToastMessage('Request sent successfully');
            } else {
                const results = await response.json();
                window.alert(results.value);
                setToastMessage('Error while sending the request');
            }
        } catch (error) {
            console.error('Error:', error);
            setToastMessage('An error occurred');
        }
        finally {
            setShowToast(true);
            setStaffids([]);
        }
    }


    function sendmessage(Sids) {
        if (Sids.length > 0) {
            window.open(`${apiUrl}/v1/Broadcast_Message.aspx?ids=${Sids.join(",")}`, '_blank')

        }
        selectedFlatRows.map(v => {
            toggleRowSelected(v.id, false)
        });
        setStaffids([]);
    }
    async function generateexcel(Sids) {
        selectedFlatRows.map(v => {
            toggleRowSelected(v.id, false)
        });
        try {
            const response = await fetch(`${apiUrl}/screeningsummary/ExportExcel/${UserId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `bearer ${jwt}`,
                },
                body: JSON.stringify(Sids.join(","))
            });
            if (response.ok && response.status === 200) {
                setToastMessage('Request sent successfully');
            } else {
                const results = await response.json();
                window.alert(results.value);
                setToastMessage('Error while sending the request');
            }
        } catch (error) {
            console.error('Error:', error);
            setToastMessage('An error occurred');
        }
        finally {
            setShowToast(true);
            setStaffids([]);
        }

    }
}
export default Table;






