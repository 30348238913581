import { Counter } from "./components/Counter";
import { FetchData } from "./components/FetchData";
import Login from "./components/Login";
import Screeningtable from "./components/Screening-Component/ScreeningSummary";
import Table from "./components/Screening-Component/TableMaster"


const AppRoutes = [
    {
        index: true,
        element: <Login />
    },
    {
        path: '/counter',
        element: <Counter />
    },
    {
        path: '/fetch-data',
        element: <FetchData />
    },
    {
        path: '/screeningsummary',
        element: <Screeningtable />
    },
];

export default AppRoutes;
