import React, { Component, useContext } from 'react'
import ReactDOM, { render } from 'react-dom';
import { useState, useEffect, useMemo, useRef } from 'react';
import { useCookies } from "react-cookie";
import RangeSlider from 'react-bootstrap-range-slider';
import { Table, SearchBox } from './TableMaster';
import { GlobalFilter, DefaultFilterForColumn, SelectColumnFilter, DateRangeColumnFilter, dateBetweenFilterFn, FiltersApply } from "./CustomFilter";
import { intialfiltervalues, intialtwofiltervalues } from './FilterInitialstate';
import LoadingSpinner from '../Loading-Component/Loading';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import StaffSearch from '../StaffSearch-Component/Staff-Search';
import DropdownMenu from '../../../node_modules/react-bootstrap/esm/DropdownMenu';
import { apiUrl } from '../../config.js';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { Modal, Container, Row, Col, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BsFillPencilFill, BsTrashFill, BsCheck2Square, BsXLg, BsFillEyeFill } from "react-icons/bs";
import "./TableStyles.scss";



function Screeningtable() {
    const [PrescreenmodalShow, setPrescreenmodalShow] = useState(false);
    const [ScreeningavailablitymodalShow, setScreeningavailablitymodalShow] = useState(false);
    const [isLoadingcompleted, setisisLoadingcompleted] = useState(false);
    const [PopupPreScreeningdata, setPopupPreScreeningdata] = useState([]);
    const [PopupScreeningAvailbalitydata, setPopupScreeningAvailbalitydata] = useState([]);
    const [FilterNames, setFilterNames] = useState([]);
    const [changepswdmodal, setchangepswdmodal] = useState(false);
    const [reset, setReset] = useState(false);
    const [screeningddls, setScreeningddls] = useState([]);
    const [screeningData, setScreeningData] = useState([]);
    const [isddlloaded, setisddlloaded] = useState(false);
    const [showSaveModal, setShowSaveModal] = useState(false);
    const [savedfilterdata, setSavedfilterdata] = useState({});
    const [ListofCompanies, setListofCompanies] = useState([]);
    const [filtersTorender, setfiltersTorender] = useState(intialfiltervalues);
    const [saveFilternames, setsaveFilternames] = useState({});
    const [loading, setLoading] = useState(true);
    const [companyNameslist, setCompanyNameslist] = useState("");
    const [globalFilterValue, setglobalFilterValue] = useState('');
    const [exportaccess, setexposeaccess] = useState(false);
    const [showModalstaffsearch, setshowModalstaffsearch] = useState(false);
    const [selectedfiltername, setSelectedfiltername] = useState({ id: '', name: '' });
    const [isEditing, setIsEditing] = useState(false);
    const [editedName, setEditedName] = useState('');
    const [originalName, setOriginalName] = useState('');
    const [Forfiltertags, setForfiltertags] = useState({
        Allfilters: intialfiltervalues,
        checkboxfilters: intialtwofiltervalues
    });
    const [Allfiltersvals, setAllfiltersvals] = useState(intialfiltervalues);
    const [checkboxfiltersvals, setcheckboxfiltersvals] = useState(intialtwofiltervalues);

    const [cookies, setCookie] = useCookies(["jwt", "coid", "loggedInTime"]);
    const jwt = cookies['jwt'];
    const coid = cookies['coid'];
    const UserId = cookies['userid'];
    const Uname = cookies['username'];
    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    };
    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            setisddlloaded(false);
            await fetch(`${apiUrl}/screeningsummary/GetScreeningwithcontext/${UserId}`, {
                headers: { "Authorization": `bearer ${jwt}` }
            })
                .then(res => res.json())
                .then(screeningData => {
                    setScreeningData(screeningData.data);
                    const val = Object.keys(screeningData).map(function (v1) {
                        // setvalues(screeningData[v1]);


                    });
                })
                .catch(error => console.log(error))
                .finally(() => { setLoading(false) });

        }
        async function fetchDDL() {

            await fetch(`${apiUrl}/screeningsummary/LoadAllDDL`, {
                headers: { "Authorization": `bearer ${jwt}` }
            })
                .then(resp => resp.json())
                .then(screeningddls => {
                    if (screeningddls.message != null) {
                        window.alert(screeningddls.message);
                        setLoading(false);
                    }
                    else {
                        fetchaccessvaribales();

                        fetchDDLforFilters();
                        if (coid === '40') {
                            fetchCompaniesList();
                        }
                        setScreeningddls(screeningddls.screeningDdls);

                        setisddlloaded(true);
                        setLoading(false);
                    }

                });


            async function fetchCompaniesList() {
                await fetch(`${apiUrl}/screeningsummary/GetListofCompanies/${UserId}`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `bearer ${jwt}`,
                    }
                }).then(res => res.json())
                    .then(ListofCompanies => {
                        if (ListofCompanies.message != null) {
                            window.alert(ListofCompanies.message);
                        }
                        else {
                            setListofCompanies(ListofCompanies.data);
                        }


                    })
            }
            async function fetchaccessvaribales() {
                await fetch(`${apiUrl}/screeningsummary/Getaccessvaribales/${UserId}`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `bearer ${jwt}`,
                    }
                }).then(res => res.json())
                    .then(exposeaccess => {

                        if (exposeaccess.message != null) {
                            window.alert(exposeaccess.message);
                        }
                        else {
                            setexposeaccess(exposeaccess.data);
                        }

                    })
            }
        }

        setfiltersTorender(intialfiltervalues);
        fetchDDL();
        const loggedInTime = formatDate(new Date());
        setCookie('loggedInTime', loggedInTime, { path: '/' });

    }, []);
    const fetchDDLforFilters = async () => {

        await fetch(`${apiUrl}/screeningsummary/GetAllFilternames/${UserId}`, {
            headers: { "Authorization": `bearer ${jwt}` }
        }).then(resp => resp.json()).then(FilterNames => {

            if (FilterNames.message != null) {
                window.alert(FilterNames.message);
            }
            else {
                setFilterNames(FilterNames.data);
            }


        });



    }
    useEffect(() => {
        fetchDDLforFilters();
        Updatefiltertags(filtersTorender);

    }, [filtersTorender])




    const MydModalWithGrid = ({ show, onHide, data }) => {

        if (isLoadingcompleted) {
            return (
                <Modal show={show} onHide={onHide} size="lg" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Pre Screening Items</Modal.Title>
                    </Modal.Header>

                    <Modal.Body >
                        <div className="model-table-container">
                            <table>
                                <thead>
                                    <tr>
                                        <th>
                                            <div className="table-cell">Name of document</div>
                                        </th>
                                        <th >
                                            <div className="table-cell">Date received</div>
                                        </th>
                                        <th >
                                            <div className="table-cell" >Status</div>
                                        </th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, index) => (
                                        <tr key={index}>
                                            <td >
                                                <div className="table-cell">{item.title}</div>
                                            </td>
                                            <td >
                                                <div className="table-cell" title={item.details} >{item.details}</div>
                                            </td>
                                            <td >
                                                <div className="table-cell svg-icon" title={"click to see reference docs"} style={{ color: item.colour }} ><a

                                                    onClick={() => {
                                                        var x = window.open();
                                                        x.document.open();
                                                        if (item.link.split(':')[0] == 'data') {

                                                            x.document.write(item.link);
                                                        }
                                                        else {
                                                            x.document.write(item.link);
                                                        }
                                                        x.document.close();
                                                    }}

                                                    style={{ color: item.colour }} ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle" viewBox="0 0 16 16">
                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                                    </svg></a></div>
                                            </td>

                                        </tr>

                                    ))}
                                </tbody>
                            </table>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={onHide}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>


            )
        }
    }

    const SliderWithInputFormControl = ({ handleslider, reset }) => {

        const [value, setValue] = React.useState(0);
        useEffect(() => {
            if (filtersTorender.Distance !== undefined && JSON.stringify(filtersTorender.Distance) !== JSON.stringify(intialfiltervalues.Distance)) {
                setValue(parseInt(filtersTorender.Distance, 10));
            }
            else if (reset) {
                setValue(0);
            } else {
                setValue(0);
            }

        }, [filtersTorender, reset]);



        const handleonChange = (e) => {
            setValue(e.target.value);
            handleslider(e.target.value);

        }



        return (
            <Form>
                <Form.Group>
                    <div className="range-slider">
                        <RangeSlider
                            value={value}
                            onChange={e => { handleonChange(e) }}
                            max={50}
                        />
                    </div>
                    <div className="range-input-value">
                        <Form.Control value={`${value} Miles`} className="custom-cursor" readOnly />
                    </div>
                </Form.Group>
            </Form>
        );

    };

    const HandlePrescreeningPopups = async (id) => {
        setisisLoadingcompleted(false);
        try {
            const response = await fetch(`${apiUrl}/screeningsummary/GetPrescreeningcontent/${id}`, {
                headers: { "Authorization": `bearer ${jwt}` }
            }).then(resp => resp.json())
                .then(PopupPreScreeningdata => {

                    setPopupPreScreeningdata(PopupPreScreeningdata.data);
                    setisisLoadingcompleted(true);
                    if (PopupPreScreeningdata.message != null) {
                        window.alert(PopupPreScreeningdata.message);
                        setisisLoadingcompleted(true);
                    }
                    else {
                        setPopupPreScreeningdata(PopupPreScreeningdata.data);
                        setisisLoadingcompleted(true);
                    }


                });



        } catch (error) {
            console.error(error);
        }
    };
    const PreScreeningavailablityWithGrid = ({ show, onHide, data }) => {

        if (isLoadingcompleted) {
            return (

                <Modal show={show} onHide={onHide} size="lg" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Pre Screening Availablity</Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <table>
                            <thead>
                                <tr>
                                    <th className="table-cell">Date</th>
                                    <th className="table-cell text-center">Allocation Name</th>
                                    <th className="table-cell">Start Time</th>
                                    <th className="table-cell">Finish Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item, index) => (
                                    <tr key={index}>
                                        <td className="table-cell" >{new Date(item.dt).toLocaleDateString('en-GB', {
                                            day: '2-digit',
                                            month: '2-digit',
                                            year: 'numeric'
                                        })}</td>

                                        <td className="table-cell" >{item.allocationname}</td>

                                        <td className="table-cell" >{item.starttime}</td>

                                        <td className="table-cell" >{item.finishtime}</td>


                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={onHide}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>


            )
        }
    }

    const Passwordconfirmationpswd = ({ show, onHide }) => {

        const [username, setUsername] = useState(Uname);
        const [password, setPassword] = useState('');




        const handlePasswordChange = (e) => {
            setPassword(e.target.value);

        }

        const handedlpswdchanges = async () => {
            if (password === '' || password === undefined) {

                window.alert('Please enter the passowrd to change the company');
            }

            else {
                try {
                    setLoading(true);
                    const response = await fetch(`${apiUrl}/Login/AuthenticateAsync`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            'username': `superadmin@${companyNameslist}`,
                            'password': password
                        })
                    });

                    if (response.ok) {
                        const logindetails = JSON.parse(await response.text())
                        setCookie("jwt", logindetails.jwt, { path: "/", secure: true, httpOnly: false });
                        setCookie("userid", logindetails.userid, { path: "/" });
                        // setCookie("coid", logindetails.coid, { path: "/" });
                        setCookie("username", logindetails.username, { path: "/" });
                        //navigate('/screeningsummary');
                    } else {
                        setCookie("jwt", "", { path: "/" });
                        setCookie("userid", "", { path: " / " });
                        // setCookie("coid", "", { path: "/" });
                        setCookie("username", "", { path: "/" });
                        alert('Invalid username or password');
                        setLoading(false);
                    }


                    if (response.status == 200 || response.statusText == "OK") {

                        window.location.reload();
                        setLoading(false);
                    }

                }
                catch (error) {
                    console.error(error);
                    setLoading(false);
                }
            }
        }

        return (
            <>
                <Modal show={show} onHide={onHide}>
                    <Modal.Header closeButton>
                        <Modal.Title>Company Change Confirmation Modal</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group controlId="formUsername">
                                <Form.Label>Username</Form.Label>
                                <Form.Control type="text" value={username} readOnly />
                            </Form.Group>

                            <Form.Group controlId="formPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" value={password} onChange={handlePasswordChange} />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={onHide}>
                            Close
                        </Button>
                        <Button variant="secondary" onClick={handedlpswdchanges}>
                            OK
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }

    const HandleScreeningAvailablityPopups = async (id) => {
        setisisLoadingcompleted(false);
        try {
            const response = await fetch(`${apiUrl}/screeningsummary/GetScreeningAvailability/${id}`, {
                headers: { "Authorization": `bearer ${jwt}` }
            }).then(resp => resp.json())
                .then(PopupScreeningAvailbalitydata => {
                    if (PopupScreeningAvailbalitydata.message != null) {
                        window.alert(PopupScreeningAvailbalitydata.message);
                        setisisLoadingcompleted(true);
                    }
                    else {
                        setPopupScreeningAvailbalitydata(PopupScreeningAvailbalitydata.data);
                        setisisLoadingcompleted(true);
                    }

                });



        } catch (error) {
            console.error(error);
        }
    };
    const EditableCell = ({
        value: initialValue,
        row: { index, original },
        column: { id, Header },
        updateMyData,
    }) => {

        const [DDLval, setDDLval] = React.useState(initialValue == null ? "Select" : initialValue)

        const onChange = (event) => {
            if (id == "referer") {
                setDDLval(event.target.value);

            }
            else {
                setDDLval(event);
            }
        }


        const onBlur = (e, i) => {
            if (id == "referer") {
                e = e.target.value;
                const selectedID = null;
                updateMyData(index, id, e, selectedID, original.staffID, Header)
            }
            else {
                const selectedItem = i.target.closest('.dropdown-item');
                const selectedID = selectedItem.getAttribute('value');
                updateMyData(index, id, e, selectedID, original.staffID, Header)
            }

        }
        React.useEffect(() => {
            if (initialValue == null) {
                initialValue = "Select";
            }
            else {
                setDDLval(initialValue)
            }

        }, [initialValue])

        const ddls = Object.keys(screeningddls);

        const tooltip = (
            <Tooltip id={`table-tooltip`}>
                {DDLval}
            </Tooltip>
        );

        if (id == "profileName") {
            const values = screeningddls[ddls[0]];
            return (

            <OverlayTrigger placement="top" overlay={tooltip} >
                <Dropdown onSelect={onChange, onBlur} >
                    <Dropdown.Toggle variant="light" id="dropdown-split-basic">
                        <div className="fixed-width">{DDLval}</div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>

                        {
                            values.map((options) =>
                                <Dropdown.Item eventKey={options.profilename} value={options.id} >{options.profilename}</Dropdown.Item>

                            )
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </OverlayTrigger>
            )
        }
        if (id == "assignedToName") {

            const values = screeningddls[ddls[1]];
            return (
            <OverlayTrigger placement = "top" overlay = { tooltip } >
                <Dropdown onSelect={onChange, onBlur} >
                    <Dropdown.Toggle variant="light" id="dropdown-split-basic">
                        <div className="fixed-width">{DDLval}</div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>


                        {
                            values.map((options) =>
                                <Dropdown.Item value={options.id} eventKey={options.screeningofficer}>{options.screeningofficer}</Dropdown.Item>

                            )
                        }
                    </Dropdown.Menu>
                  </Dropdown>
             </OverlayTrigger>
            )
        }
        if (id == "statusDescription") {

            const values = screeningddls[ddls[2]];

            return (
                <>
               <OverlayTrigger placement="top" overlay={tooltip}>
                <Dropdown onSelect={onChange, onBlur} >
                    <Dropdown.Toggle variant="light" id="dropdown-split-basic">
                        <div className="fixed-width">{DDLval}</div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>

                        {
                            values.map((options) =>
                                <Dropdown.Item value={options.id} eventKey={options.statusname}>{options.statusname}</Dropdown.Item>

                            )
                        }
                    </Dropdown.Menu>
                </Dropdown >

               </OverlayTrigger>
                </>
            )
        }
        if (id == "areaManagerName") {

            const values = screeningddls[ddls[3]];
            return (
             <OverlayTrigger placement="top" overlay={tooltip}>
                <Dropdown onSelect={onChange, onBlur} >
                    <Dropdown.Toggle variant="light" id="dropdown-split-basic">
                        <div className="fixed-width">{DDLval}</div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>

                        {
                            values.map((options) =>
                                <Dropdown.Item value={options.id} eventKey={options.areamanger}>{options.areamanger}</Dropdown.Item>

                            )
                        }
                    </Dropdown.Menu>
                 </Dropdown >
            </OverlayTrigger>
            )
        }
        if (id == "dep1") {

            const values = screeningddls[ddls[4]];
            return (
            <OverlayTrigger placement="top" overlay={tooltip}>
                <Dropdown onSelect={onChange, onBlur} >
                    <Dropdown.Toggle variant="light" id="dropdown-split-basic">
                        <div className="fixed-width">{DDLval}</div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>

                        {
                            values.map((options) =>
                                <Dropdown.Item eventKey={options.workbase}>{options.workbase}</Dropdown.Item>

                            )
                        }
                    </Dropdown.Menu>
                </Dropdown >
            </OverlayTrigger>
            )
        }
        if (id == "dep2") {

            const values = screeningddls[ddls[5]];
            return (
            <OverlayTrigger placement="top" overlay={tooltip}>
                <Dropdown onSelect={onChange, onBlur} >
                    <Dropdown.Toggle variant="light" id="dropdown-split-basic">
                        <div className="fixed-width">{DDLval}</div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>

                        {
                            values.map((options) =>
                                <Dropdown.Item eventKey={options.department2}>{options.department2}</Dropdown.Item>

                            )
                        }
                    </Dropdown.Menu>
                </Dropdown >
            </OverlayTrigger>
            )
        }
        if (id == "dep3") {

            const values = screeningddls[ddls[6]];
            return (
            <OverlayTrigger placement="top" overlay={tooltip}>
                <Dropdown onSelect={onChange, onBlur} >
                    <Dropdown.Toggle variant="light" id="dropdown-split-basic">
                        <div className="fixed-width">{DDLval}</div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>

                        {
                            values.map((options) =>
                                <Dropdown.Item eventKey={options.department3}>{options.department3}</Dropdown.Item>

                            )
                        }
                    </Dropdown.Menu>
                </Dropdown >
            </OverlayTrigger>
            )
        }
        if (id == "referer") {

            if (DDLval === "Select") {
                return <input onChange={onChange} placeholder={"Enter Referrer"} className="rounded referrer-input" onBlur={onBlur} />
            }
            return <input value={DDLval} onChange={onChange} placeholder={"Enter Referrer"} className="rounded referrer-input" onBlur={onBlur} />


        }
    }
    const renderTooltip = (street, houseno, postcode, setShowTooltip) => (
        <Tooltip className="custom-tooltip" >
            House No: {houseno}
            <br />
            Street: {street}
            <br />
            Post Code : {postcode}
            <br />

        </Tooltip>
    );
    const LocationCell = ({ cell, row }) => {
        const [showTooltip, setShowTooltip] = useState(false);
        return (
            <OverlayTrigger
                trigger="click"

                placement="top"
                overlay={renderTooltip(row.original.street_1, row.original.houseNo, row.original.postCode, setShowTooltip)}
                rootClose
            >
                <span style={{ cursor: "pointer" }}>{cell.value}</span>
            </OverlayTrigger>
        );
    };
    const filterColumns = (columns) => {
        return columns.filter((column) => {
            if (column.accessor === "companyname") {
                return column.isVisible = (cookies.userid == 1);
            }
            return true;
        });
    };
    const columns = React.useMemo(

        () => [
            {
                Header: "Vetting Profile",
                accessor: "Edit",
                // Filter: SelectColumnFilter,
                filter: 'equals',
                filterable: false,
                hidden: false,
                Cell: ({ cell: { value }, row: { original } }) => (
                    <a href={`${apiUrl}/v1/vetting_management.aspx?id=${original.staffID}`} rel="nofollow" style={{ color: 'white' }} target="_blank">
                        <BsFillEyeFill />
                    </a>
                ),
            },
            {
                Header: "Company",
                accessor: "companyname",
                Filter: SelectColumnFilter,
                filter: 'equals',
                filterable: false,
                hidden: (cookies.userid != 1),
                isVisible: (cookies.userid == 1),


            },
            {
                Header: "ID",
                accessor: "staffID",
                defaultCanFilter: false,
                filterable: false,
                hidden: true,
            },
            {
                Header: "Last Name",
                accessor: "lastname",
                filter: 'equals',
                filterable: false,
                hidden: true
            },
            {
                Header: "First Name",
                accessor: "firstname",
                filter: 'equals',
                filterable: false,
                hidden: true
            },
            {
                Header: "Middle Name",
                accessor: "middlename",
                filter: 'equals',
                filterable: false,
                hidden: false
            },
            {
                Header: "Assigned To",
                accessor: "assignedToName",
                Filter: SelectColumnFilter,
                filter: 'equals',
                defaultCanFilter: false,
                filterable: true,
                Cell: EditableCell,
            },
            {
                Header: "Profile",
                accessor: "profileName",
                Filter: SelectColumnFilter,
                filter: 'equals',
                filterable: true,
                Cell: EditableCell,
                hidden: true


            },
            {
                Header: "Referrer",
                accessor: "referer",
                //Filter: SelectColumnFilter,
                filter: 'equals',
                filterable: false,
                Cell: EditableCell,
                hidden: false

            },
            {
                Header: "Engagement Method",
                accessor: "engagementMethodDescription",
                Filter: SelectColumnFilter,
                filter: 'equals',
                filterable: true,
                hidden: false
            },
            {
                Header: "Location",
                accessor: "post_Town",
                Filter: DefaultFilterForColumn,
                filter: 'equals',
                filterable: false,
                hidden: false,
                Cell: ({ cell, row }) => <LocationCell cell={cell} row={row} />,
            },
            {
                Header: "Status",
                accessor: "statusDescription",
                Filter: SelectColumnFilter,
                filter: 'equals',
                filterable: true,
                Cell: EditableCell,
                hidden: false
            },
            {
                Header: "Contact Details",
                accessor: "mobileNo",
                //  Filter: SelectColumnFilter,
                filter: 'equals',
                filterable: false,
                hidden: false
            },
            {
                Header: " Application Date",
                accessor: "applicationDate",
                Filter: DateRangeColumnFilter,
                filter: dateBetweenFilterFn,
                filterable: true,
                hidden: false,
                Cell: ({ value }) => {
                    if (value == null) {
                        return <span>{value}</span>;
                    } else {
                        const dateOnly = new Date(value);

                        const formattedDate = new Intl.DateTimeFormat('en-GB', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                        }).format(dateOnly);

                        return <span>{formattedDate}</span>;
                    }
                }
            },

            {
                Header: "Last Status Change",
                accessor: "lastchangedate",
                //  Filter: SelectColumnFilter,
                filter: 'equals',
                filterable: false,
                hidden: false,
                Cell: ({ value }) => {
                    if (value == null) {
                        return <span>{value}</span>;
                    } else {
                        const dateOnly = new Date(value);

                        const formattedDate = new Intl.DateTimeFormat('en-GB', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                        }).format(dateOnly);

                        return <span>{formattedDate}</span>;
                    }
                }
            },
            {
                Header: "Screening Started",
                accessor: "screeningstarted",
                filter: 'equals',
                filterable: false,
                hidden: false,
                Cell: ({ value }) => {
                    if (value == null) {
                        return <span>{value}</span>;
                    } else {
                        const dateOnly = new Date(value);

                        const formattedDate = new Intl.DateTimeFormat('en-GB', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                        }).format(dateOnly);

                        return <span>{formattedDate}</span>;
                    }
                }
            },
            {
                Header: "Date Deployed",
                accessor: "datedeployed",
                // Filter: SelectColumnFilter,
                filter: 'equals',
                filterable: false,
                hidden: false,
                Cell: ({ value }) => {
                    if (value == null) {
                        return <span>{value}</span>;
                    } else {
                        const dateOnly = new Date(value);

                        const formattedDate = new Intl.DateTimeFormat('en-GB', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                        }).format(dateOnly);

                        return <span>{formattedDate}</span>;
                    }
                }
            },
            {
                Header: "Date Last Worked",
                accessor: "datelastworked",
                // Filter: SelectColumnFilter,
                filter: 'equals',
                filterable: false,
                hidden: false,
                Cell: ({ value, row: { original } }) => {
                    if (value == null) {
                        return <span>{value}</span>;
                    } else {
                        const dateOnly = new Date(value);

                        const formattedDate = new Intl.DateTimeFormat('en-GB', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                        }).format(dateOnly);

                        return (
                            <Button variant="primary" onClick={() => {
                                HandleScreeningAvailablityPopups(original.staffID);
                                setScreeningavailablitymodalShow(true);
                            }}>
                                {formattedDate}
                            </Button>
                        )
                    }

                }

            },
            {
                Header: "Days Since Last Shift",
                accessor: "dayssincelastshift",
                //  Filter: SelectColumnFilter,
                filter: 'equals',
                filterable: false,
                hidden: false
            },
            {
                Header: "Docs Last Chased",
                accessor: "docslastchased",
                // Filter: SelectColumnFilter,
                filter: 'equals',
                filterable: false,
                hidden: false,
                Cell: ({ value }) => {
                    if (value == null) {
                        return <span>{value}</span>;
                    } else {
                        const dateOnly = new Date(value);

                        const formattedDate = new Intl.DateTimeFormat('en-GB', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                        }).format(dateOnly);

                        return <span>{formattedDate}</span>;
                    }
                }
            },
            {
                Header: "Refs Last Chased",
                accessor: "refslastchased",
                // Filter: SelectColumnFilter,
                filter: 'equals',
                filterable: false,
                hidden: false,
                Cell: ({ value }) => {
                    if (value == null) {
                        return <span>{value}</span>;
                    } else {
                        const dateOnly = new Date(value);

                        const formattedDate = new Intl.DateTimeFormat('en-GB', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                        }).format(dateOnly);

                        return <span>{formattedDate}</span>;
                    }
                }
            },
            {
                Header: "Prescreen Status",
                accessor: "prescreenstatus",
                // Filter: SelectColumnFilter,
                filter: 'equals',
                filterable: false,
                hidden: false,
                Cell: ({ value, row: { original } }) => {
                    return (
                        <Button variant="primary" onClick={() => {
                            HandlePrescreeningPopups(original.staffID);
                            setPrescreenmodalShow(true);
                        }}>
                            {value}
                        </Button>
                    )
                }

            },
            {
                Header: "References",
                accessor: "refs",
                // Filter: SelectColumnFilter,
                filter: 'equals',
                filterable: false,
                hidden: false
            },
            {
                Header: "Years Covered",
                accessor: "yearscovered",
                //  Filter: SelectColumnFilter,
                filter: 'equals',
                filterable: false,
                hidden: false
            },
            {
                Header: "Days to Complete",
                accessor: "daysleft",
                //Filter: SelectColumnFilter,
                filter: 'equals',
                filterable: false,
                hidden: false
            },
            {
                Header: "Dep 1",
                accessor: "dep1",
                //Filter: SelectColumnFilter,
                filter: 'equals',
                filterable: false,
                hidden: false,
                Cell: ({ cell: { value }, row: { original } }) => (
                    <a href={`https://www.c247.pro/v1/vetting_management.aspx?id=${original.staffID}`} rel="nofollow" style={{ color: 'white' }} target="_blank">VIEW</a>
                ),


            },
            {
                Header: "Dep 2",
                accessor: "dep2",
                Filter: SelectColumnFilter,
                filter: 'equals',
                filterable: true,
                Cell: EditableCell,
                hidden: false

            },
            {
                Header: "Dep 3",
                accessor: "dep3",
                Filter: SelectColumnFilter,
                filter: 'equals',
                filterable: true,
                Cell: EditableCell,
                hidden: false
            },
            {
                Header: "AM",
                accessor: "areaManagerName",
                // Filter: SelectColumnFilter,
                filter: 'equals',
                filterable: false,
                Cell: EditableCell,
                hidden: false
            },

        ]
    );
    const Datecomponent = ({ parentCallback, filterdData }) => {
        const [disabled, setDisabled] = useState(filterdData.Customdate !== 'custom');

        const handleOptionChange = (value) => {

            if (value === "custom") {

                parentCallback({ ...filterdData, Customdate: value });
                setDisabled(false);
            } else {
                setDisabled(true);
                parentCallback({ ...filterdData, AppliedFrom: '', AppliedTo: '', Customdate: value });

            }
        };
        const handelAppliedFromChange = (value) => {

            let customfromValue = value ? moment(value).format('YYYY-MM-DDTHH:mm:ss') : '';
            parentCallback({ ...filterdData, AppliedFrom: customfromValue });

        }

        const handelAppliedToChange = (value) => {



            let customToValue = value ? moment(value).format('YYYY-MM-DDTHH:mm:ss') : '';
            parentCallback({ ...filterdData, AppliedTo: customToValue });

        }
        const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
            <input
                type="text"
                value={value}
                onClick={onClick}
                className="btn btn-light date-input"
                ref={ref}
                readOnly
                style={{ width: '100%' }}
            />
        ));
        return (
            <div className="Applicationdate">
                <section className="firstrow">
                    <div className="form-group mb-0">
                        <label>
                            <input
                                type="radio"
                                value="yesterday"
                                checked={filterdData.Customdate ? filterdData.Customdate.includes("yesterday") : false}
                                onChange={(e) => handleOptionChange(e.target.value)}
                                className="label_space me-1"
                            />
                            Yesterday
                        </label>
                    </div>
                    <div className="form-group mb-0">
                        <label>
                            <input
                                type="radio"
                                value="today"
                                checked={filterdData.Customdate ? filterdData.Customdate.includes("today") : false}
                                onChange={(e) => handleOptionChange(e.target.value)}
                                className="label_space me-1"
                            />
                            Today
                        </label>
                    </div>
                    <div className="form-group my-0">
                        <label >
                            <input
                                type="radio"
                                value="Last 7 days"
                                checked={filterdData.Customdate ? filterdData.Customdate.includes("7") : false}
                                onChange={(e) => handleOptionChange(e.target.value)}
                                className="label_space me-1"
                            />
                            Last 7 days
                        </label>
                    </div>
                    <div className="form-group my-0">
                        <label>
                            <input
                                type="radio"
                                value="Last 30 days"
                                checked={filterdData.Customdate ? filterdData.Customdate.includes("30") : false}
                                onChange={(e) => handleOptionChange(e.target.value)}
                                className="label_space me-1"

                            />
                            Last 30 days
                        </label>
                    </div>
                    <div className="form-group my-0">
                        <label>
                            <input
                                type="radio"
                                value="custom"
                                checked={filterdData.Customdate ? filterdData.Customdate.includes("custom") : false}
                                onChange={(e) => handleOptionChange(e.target.value)}
                                className="label_space me-1"
                            />
                            Custom
                        </label>
                    </div>
                </section>
                <section className={`secondrow ${filterdData.Customdate.includes("custom") === false ? "d-none" : "d-flex"}`}>
                    <div className="form-group me-2 mb-0">
                        <label htmlFor="AppliedFrom">
                            Applied From:
                        </label>
                        < DatePicker
                            selected={!filterdData.AppliedFrom ? null : new Date(filterdData.AppliedFrom)}
                            onChange={handelAppliedFromChange}
                            isClearable
                            dateFormat="dd/MM/yyyy"
                            customInput={<CustomInput />}
                            disabled={disabled}
                        />

                    </div>
                    <div className="form-group mb-0">
                        <label htmlFor="AppliedTo">
                            Applied To:
                        </label>
                        < DatePicker
                            selected={!filterdData.AppliedTo ? null : new Date(filterdData.AppliedTo)}
                            onChange={handelAppliedToChange}
                            isClearable
                            dateFormat="dd/MM/yyyy"
                            customInput={<CustomInput />}
                            disabled={disabled}
                        />

                    </div>
                </section>
            </div>
        );
    };




    const FilterForm = ({ onSubmit, onSave }) => {
        const [show, setShow] = useState(false);
        const [searchTerm, setSearchTerm] = useState("");
            
        const [filterdData, setfilterData] = useState(intialfiltervalues);
        const [firsttwofilters, setfirsttwofilters] = useState(intialtwofiltervalues);
        const extractBooleanValues = (obj) => {
            const result = [];
            for (const key in obj) {
                if (typeof obj[key] === 'boolean' && obj[key] === true) {
                    result.push(key);
                }
            }
            return result;
        };

        const [visibleColumns, setVisibleColumns] = useState(columns.map((col) => col.accessor));

        useEffect(() => {
            if (JSON.stringify(filtersTorender) !== JSON.stringify(intialfiltervalues)) {
                setfilterData(filtersTorender);
                setfirsttwofilters(saveFilternames);
                //while applying filter from saved filter it we are captilizing the names for filter,
                //here we are converting into same values as intitalfiltervalues
                const initialFilterValuesKeys = Object.keys(intialfiltervalues);
                const lowerCaseAnotherObject = {};
                for (let key in filtersTorender) {
                    lowerCaseAnotherObject[key.toLowerCase()] = filtersTorender[key];
                }
                const matchingKeyValuePairs = {};
                initialFilterValuesKeys.forEach(key => {
                    let lowerCaseKey = key.toLowerCase();
                    if (lowerCaseAnotherObject.hasOwnProperty(lowerCaseKey)) {
                        matchingKeyValuePairs[key] = lowerCaseAnotherObject[lowerCaseKey];
                    }
                });
                setVisibleColumns((prevVisibleColumns) => {
                    const columnStates = extractBooleanValues(matchingKeyValuePairs);

                    return prevVisibleColumns.map((col) => {
                        if (columnStates.includes(col)) {
                            return col;
                        }
                        return null;
                    }).filter(Boolean);
                });
                //This code is only comes into effect when user selectes the filter from dropdown
                if (selectedfiltername.id !== '') {
                    const profileObject = applicationprofileValues.find(item => item.id.toString() === filtersTorender.Profile);
                    if (profileObject) {
                        setfirsttwofilters(prevState => ({ ...prevState, Profile: profileObject.profilename }));
                    }
                    const AssignedtoObject = assignedToValues.find(item => item.id.toString() === filtersTorender.AssignedTo);
                    if (AssignedtoObject) {
                        setfirsttwofilters(prevState => ({ ...prevState, AssignedTo: AssignedtoObject.screeningofficer }));
                    }

                    const statusIds = filtersTorender.Status.split(',');
                    const statusNames = statusIds.map(id => {
                        const statusObject = statusValues.find(item => item.id.toString() === id);
                        return statusObject ? statusObject.statusname : '';
                    });
                    if (statusNames) {
                        setfirsttwofilters(prevState => ({ ...prevState, Status: statusNames }));
                    }
                }
            }
        }, [filtersTorender]);


        const handleReset = (e) => {
            e.preventDefault();
            setfiltersTorender(intialfiltervalues);
            setfilterData(intialfiltervalues);
            setfirsttwofilters(intialtwofiltervalues);
            setSelectedfiltername({ id: '', name: '' });
            setReset(!reset);
            setScreeningData([]);
            setForfiltertags({
                Allfilters: intialfiltervalues,
                checkboxfilters: intialtwofiltervalues
            });

        };

      
        const handelLocation = (e) => {
            setfilterData({ ...filterdData, Location: e.target.value });
        }

        const handelSearchInputChange = (event) => {
            setSearchTerm(event.target.value);
        };


        const handelStatusChange = (e, value, checked) => {

            setfilterData((prevData) => {
                const updatedValue = Array.isArray(value) ? value.join(",") : value.toString();

                if (checked) {

                    const updatedStatus = prevData.Status ? `${prevData.Status},${updatedValue}` : updatedValue;
                    return {
                        ...prevData,
                        Status: updatedStatus,
                    };
                } else {

                    const updatedStatus = prevData.Status
                        ? prevData.Status.split(',').filter((status) => status !== updatedValue).join(',')
                        : '';
                    return {
                        ...prevData,
                        Status: updatedStatus,
                    };
                }
            });
            setfirsttwofilters((prevState) => {

                if (prevState.Status.includes(e)) {
                    return {
                        ...prevState,
                        Status: prevState.Status.filter((name) => name !== e),
                    };
                } else {
                    return {
                        ...prevState,
                        Status: [...prevState.Status, e],
                    };
                }
            });



        }
        const handelProfileChange = (e, value) => {
            setfilterData({ ...filterdData, Profile: value });
            setfirsttwofilters({ ...firsttwofilters, Profile: e });
        }

        const handelAssignedToChange = (e, value) => {
            setfilterData({ ...filterdData, AssignedTo: value });
            setfirsttwofilters({ ...firsttwofilters, AssignedTo: e });
        }
        const handelDepartment1Change = (value) => {
            setfilterData({ ...filterdData, Department1: value });
        }
        const handelDepartment2Change = (value) => {
            setfilterData({ ...filterdData, Department2: value });
        }
        const handelDepartment3Change = (value) => {
            setfilterData({ ...filterdData, Department3: value });
        }

        const handelEngagementChange = (value) => {
            setfilterData({ ...filterdData, Engagement: value });
        }
        const handleslider = (value) => {
            const values = value == 0 ? '' : value;
            setfilterData({ ...filterdData, Distance: values.toString() });
        }
        const HandleColumnVisibilityChange = (id, isVisible) => {

            setfilterData({
                ...filterdData, [id]: isVisible
            });

            setVisibleColumns((prevVisibleColumns) => {
                if (prevVisibleColumns.includes(id)) {
                    return prevVisibleColumns.filter((col) => col !== id);
                } else {
                    return [...prevVisibleColumns, id];
                }
            });
        }

        const handleSubmit = (e) => {
            e.preventDefault();
            setsaveFilternames(firsttwofilters);
            onSubmit(filterdData);
            setForfiltertags({ Allfilters: filterdData, checkboxfilters: firsttwofilters });
         
        };
        const handleSave = (e) => {
            e.preventDefault();

            if (JSON.stringify(filterdData) === JSON.stringify(intialfiltervalues)) {
                window.alert('Please select the filters to save');
            } else {
                onSave(filterdData);
                setsaveFilternames(firsttwofilters);
            }
            setForfiltertags({ Allfilters: filterdData, checkboxfilters: firsttwofilters });
            

        };
  
       
        const Ddls = Object.keys(screeningddls);
        const applicationprofileValues = screeningddls[Ddls[0]];
        const assignedToValues = screeningddls[Ddls[1]];
        const statusValues = screeningddls[Ddls[2]];
        const Dep1values = screeningddls[Ddls[4]];
        const Dep2values = screeningddls[Ddls[5]];
        const Dep3values = screeningddls[Ddls[6]];
        const Engagement = screeningddls[Ddls[7]];

        //search section for dropdowns
        const filteredAssignedTo = assignedToValues && assignedToValues.length > 0
            ? assignedToValues.filter((options) => {
                if (options.screeningofficer && options.screeningofficer.trim() !== '') {

                    return options.screeningofficer.toLowerCase().includes(searchTerm.toLowerCase())
                }
                return false;
            }

            )
            : [];
        const filteredApplicationprofile = applicationprofileValues && applicationprofileValues.length > 0
            ? applicationprofileValues.filter((options) => {
                if (options.profilename && options.profilename.trim() !== '') {
                    return options.profilename.toLowerCase().includes(searchTerm.toLowerCase())
                }
                return false;
            }
            )
            : [];
        const filteredstatus = statusValues && statusValues.length > 0
            ? statusValues.filter((options) =>
                options.statusname.toLowerCase().includes(searchTerm.toLowerCase())
            )
            : [];
        const filteredDep1 = Dep1values && Dep1values.length > 0
            ? Dep1values.filter((options) => {
                if (options.workbase && options.workbase.trim() !== '') {
                    return options.workbase.toLowerCase().includes(searchTerm.toLowerCase());
                }
                return false;
            })
            : [];

        const filteredDep2 = Dep2values && Dep2values.length > 0
            ? Dep2values.filter((options) => {
                if (options.department2 && options.department2.trim() !== '') {
                    return options.department2.toLowerCase().includes(searchTerm.toLowerCase());
                }
                return false;
            })
            : [];
        const filteredDep3 = Dep3values && Dep3values.length > 0
            ? Dep3values.filter((options) => {
                if (options.department3 && options.department3.trim() !== '') {
                    return options.department3.toLowerCase().includes(searchTerm.toLowerCase());
                }
                return false;
            })
            : [];
        const filteredEngagement = Engagement && Engagement.length > 0
            ? Engagement.filter((options) => {
                if (options.engagement && options.engagement.trim() !== '') {
                    return options.engagement.toLowerCase().includes(searchTerm.toLowerCase());
                }
                return false;
            })
            : [];
        const filteredcustomization = columns && columns.length > 0
            ? columns.filter((options) => {
                if (options.Header && options.Header.trim() !== '') {
                    return options.Header.toLowerCase().includes(searchTerm.toLowerCase());
                }
                return false;
            })
            : [];


        if (isddlloaded) {
            return (
                <>

                    <form onSubmit={handleSubmit} className="form-inline container two-col-filters">
                        <div className="row">
                            <div className="col-6 border-end">
                                <h4>Status</h4>
                                {filteredstatus.map((option) => (
                                    <div style={{ display: 'flex' }} key={option.id}>
                                        <input
                                            type="checkbox"
                                            className="dropdown-checkbox"
                                            checked={firsttwofilters && firsttwofilters.Status && firsttwofilters.Status.includes(option.statusname)}
                                            id={`status-${option.id}`}
                                            value={option.id}
                                            onChange={(e) => handelStatusChange(option.statusname, option.id, e.target.checked)}
                                        />
                                        <label htmlFor={`status-${option.id}`}>{option.statusname}</label>
                                    </div>
                                ))}
                                <h4 className="my-2">Table columns:</h4>
                                {filteredcustomization.map((column, index) => (
                                    !column.hidden && (
                                        <div key={column.accessor}>
                                            <input
                                                type="checkbox"
                                                className="dropdown-checkbox"
                                                checked={visibleColumns.includes(column.accessor)}
                                                onChange={(e) => {
                                                    HandleColumnVisibilityChange(column.accessor, e.target.checked)
                                                }}
                                            />
                                            <span>{column.Header}</span>
                                        </div>
                                    )
                                ))}
                            </div>
                            <div className="col-6">
                                <h4>Filters:</h4>
                        <div className="filter_Container_one mb-3">
                            <div className="form-group location-container">
                                <label htmlFor="location" className="mr-2">
                                    Location:
                                </label>
                                <input type="text" id="location" placeholder="post code/town/address" className="form-control mr-2" value={filterdData.Location} onChange={handelLocation} maxLength="50" />
                                <SliderWithInputFormControl handleslider={handleslider} reset={reset} />
                            </div>


                            <div className="form-group">
                                <label htmlFor="Department1" className="mr-2">
                                    Dep 1:
                                </label>
                                <Dropdown title={filterdData.Department1} onSelect={handelDepartment1Change} onClick={e => { setSearchTerm("") }}>
                                    <Dropdown.Toggle variant="light" id="dropdown-split-basic" className="mr-2">
                                        <div className="fixed-width"> {filterdData.Department1 || 'Select Department1'}</div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <input type="text" placeholder="Search..." value={searchTerm} className="search-input" onChange={handelSearchInputChange} />
                                        {filteredDep1.map((options) => <Dropdown.Item eventKey={options.workbase}>{options.workbase}</Dropdown.Item>

                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>



                        </div>
                        <div className="filter_Container_two">


                            <div className="form-group app_date_container">
                                <label htmlFor="Application Date" className="mr-2">
                                    Application Date:
                                        </label>
                                <div className="application-date-parent">
                                    <Datecomponent parentCallback={setfilterData} filterdData={filterdData} />
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="profile" className="mr-2">
                                    Profile:
                                </label>
                                <Dropdown title={firsttwofilters.Profile} onClick={e => { setSearchTerm("") }}>
                                    <Dropdown.Toggle variant="light" id="dropdown-split-basic" className="mr-2" >
                                        <div className="fixed-width">{firsttwofilters.Profile || 'Select Profile'}</div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <input type="text" placeholder="Search..." value={searchTerm} className="search-input" onChange={handelSearchInputChange} />
                                        {filteredApplicationprofile.map((options) => <Dropdown.Item onClick={(e) => handelProfileChange(options.profilename, options.id.toString())} eventKey={options.id} value={options.id}>{options.profilename}</Dropdown.Item>
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>


                            <div className="form-group">
                                <label htmlFor="Engagement" className="mr-2">
                                    Engagement:
                                </label>
                                <Dropdown title={filterdData.Engagement} onSelect={handelEngagementChange} onClick={e => { setSearchTerm("") }}>
                                    <Dropdown.Toggle variant="light" id="dropdown-split-basic" className="mr-2">
                                        <div className="fixed-width">{filterdData.Engagement || 'Select Engagement'}</div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <input type="text" placeholder="Search..." value={searchTerm} className="search-input" onChange={handelSearchInputChange} />
                                        {filteredEngagement.map((options) => <Dropdown.Item eventKey={options.engagement}>{options.engagement}</Dropdown.Item>
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>

                            <div className="form-group">
                                <label htmlFor="Department2" className="mr-2">
                                    Dep 2:
                                </label>
                                <Dropdown title={filterdData.Department2} onSelect={handelDepartment2Change} onClick={e => { setSearchTerm("") }}>
                                    <Dropdown.Toggle variant="light" id="dropdown-split-basic" className="mr-2">
                                        <div className="fixed-width">{filterdData.Department2 || 'Select Department2'}</div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <input type="text" placeholder="Search..." value={searchTerm} className="search-input" onChange={handelSearchInputChange} />
                                        {filteredDep2.map((options) => <Dropdown.Item eventKey={options.department2}>{options.department2}</Dropdown.Item>

                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>

                        </div>

                        <div className="filter_Container_three">
                            <div className="form-group">
                                <label htmlFor="AssignedTo" className="mr-2">
                                    Assigned To:
                                </label>
                                <Dropdown title={firsttwofilters.AssignedTo} onClick={e => { setSearchTerm("") }}>
                                    <Dropdown.Toggle variant="light" id="dropdown-split-basic" className="mr-2" >
                                        <div className="fixed-width">  {firsttwofilters.AssignedTo || 'Select AssignedTo'}</div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <input type="text" placeholder="Search..." value={searchTerm} className="search-input" onChange={handelSearchInputChange} />
                                        {filteredAssignedTo.map((options) => <Dropdown.Item onClick={(e) => handelAssignedToChange(options.screeningofficer, options.id.toString())} eventKey={options.id} value={options.id} >{options.screeningofficer}</Dropdown.Item>
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>

                            <div className="form-group" style={{ display: 'block' }}>
                                <label htmlFor="Department3" className="mr-2">
                                    Dep 3:
                                </label>
                                <Dropdown title={filterdData.Department3} onSelect={handelDepartment3Change} onClick={e => { setSearchTerm("") }}>
                                    <Dropdown.Toggle variant="light" id="dropdown-split-basic" className="mr-2 ">
                                        <div className="fixed-width">{filterdData.Department3 || 'Select Department3'}</div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <input type="text" placeholder="Search..." value={searchTerm} className="search-input" onChange={handelSearchInputChange} />
                                        {filteredDep3.map((options) => <Dropdown.Item eventKey={options.department3}>{options.department3}</Dropdown.Item>

                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>

                        </div>
                        <div className="submit-container">
                            <div className="d-flex my-4">
                                <button onClick={handleSave} className="btn btn-outline-primary submit-filter me-1">
                                    Save Filters
                                </button>
                                <button type="submit" className="btn btn-primary submit-filter ms-1" >
                                    Apply Filters
                                </button>
                            </div>
                            <div className="mb-4">
                                <Button className="submit-filter btn btn-outline-primary" onClick={handleReset}>Reset Filters</Button>
                            </div>
                                </div>
                    </div>
                    </div>
                    </form>
                  
                </>
            )
        }
    }
    function Updatefiltertags(filtersTorender) {
     

        const Ddls = Object.keys(screeningddls);
        const applicationprofileValues = screeningddls[Ddls[0]];
        const assignedToValues = screeningddls[Ddls[1]];
        const statusValues = screeningddls[Ddls[2]];
 

        if (JSON.stringify(filtersTorender) !== JSON.stringify(intialfiltervalues)) {
            setAllfiltersvals(filtersTorender);

            //while applying filter from saved filter it we are captilizing the names for filter,
            //here we are converting into same values as intitalfiltervalues
            const initialFilterValuesKeys = Object.keys(intialfiltervalues);
            const lowerCaseAnotherObject = {};
            for (let key in filtersTorender) {
                lowerCaseAnotherObject[key.toLowerCase()] = filtersTorender[key];
            }
            const matchingKeyValuePairs = {};
            initialFilterValuesKeys.forEach(key => {
                let lowerCaseKey = key.toLowerCase();
                if (lowerCaseAnotherObject.hasOwnProperty(lowerCaseKey)) {
                    matchingKeyValuePairs[key] = lowerCaseAnotherObject[lowerCaseKey];
                }
            });

            if (selectedfiltername.id !== '') {
                const profileObject = applicationprofileValues.find(item => item.id.toString() === filtersTorender.Profile);
                if (profileObject) {
                    setcheckboxfiltersvals(prevState => ({ ...prevState, Profile: profileObject.profilename }));
                }
                const AssignedtoObject = assignedToValues.find(item => item.id.toString() === filtersTorender.AssignedTo);
                if (AssignedtoObject) {
                    setcheckboxfiltersvals(prevState => ({ ...prevState, AssignedTo: AssignedtoObject.screeningofficer }));
                }

                const statusIds = filtersTorender.Status.split(',');
                const statusNames = statusIds.map(id => {
                    const statusObject = statusValues.find(item => item.id.toString() === id);
                    return statusObject ? statusObject.statusname : '';
                });
                if (statusNames) {
                    setcheckboxfiltersvals(prevState => ({ ...prevState, Status: statusNames }));
                }
            }

        }
        else {
            setAllfiltersvals(filtersTorender);
        }

    }
  
    useEffect(() => {
        setForfiltertags({ Allfilters: Allfiltersvals, checkboxfilters: checkboxfiltersvals });
    }, [Allfiltersvals, checkboxfiltersvals])

    const Savedfilter = () => {
        const [searchTerm, setSearchTerm] = useState("");


        const handelSearchInputChange = (event) => {
            setSearchTerm(event.target.value);
        };
        const handleSaveFilter = async (filterName) => {
            setLoading(true);
            setfiltersTorender(savedfilterdata);
            try {

                const response = await fetch(`${apiUrl}/screeningsummary/SaveScreeningSummaryDataWithFilter/${UserId}`, {
                    method: 'POST',
                    headers: {
                        "Authorization": `bearer ${jwt}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ savedfilterdata, filterName }),
                });
                if (response.status == 204 || response.statusText == "No Content") {
                    window.alert("No results found");
                    setLoading(false);
                } else {
                    const result = await response.json();
                    setScreeningData(result);
                    fetchDDLforFilters();
                    setLoading(false);

                }

            } catch (error) {
                console.error(error);
            }

            setShowSaveModal(false);


        };
        const FilteredFilternames = FilterNames && FilterNames.length > 0
            ? FilterNames.filter((options) => {
                if (options.name && options.name.trim() !== '') {
                    return options.name.toLowerCase().includes(searchTerm.toLowerCase());
                }
                return false;
            })
            : [];

        function capitalizeFirstLetter(str) {
            if (!str || typeof str !== 'string') return str;
            return str.charAt(0).toUpperCase() + str.slice(1);
        }

        function capitalizeKeys(obj) {
            const capitalizedObj = {};
            for (const key in obj) {
                const capitalizedKey = capitalizeFirstLetter(key);
                capitalizedObj[capitalizedKey] = obj[key];
            }
            return capitalizedObj;
        }

        const handelfilterApply = async (e, i) => {

            let id = e;
            let filtername = i;

            setLoading(true);
            try {
                const response = await fetch(`${apiUrl}/screeningsummary/GetSSDataWithFiltersSaved/${UserId}`, {
                    method: 'POST',
                    headers: {
                        "Authorization": `bearer ${jwt}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ id: id, filtername: filtername }),

                });

             
                    const result = await response.json();
                if (result.message != null) {
                    window.alert(result.message);
                    setLoading(false);
                }
                else if (result.data) {
                    const capitalizedResult = {
                        //sSummaryData: result.sSummaryData.map(capitalizeKeys),
                        sSummaryHeaders: capitalizeKeys(result.data.sSummaryHeaders),
                    };

                    setScreeningData(result.data.sSummaryData);
                    setfiltersTorender(capitalizedResult.sSummaryHeaders);
                    setLoading(false);
                   
                  
                }
                else {
                    window.alert("No results found");
                    setLoading(false);
                }
                
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
            setSelectedfiltername({ id: e.toString(), name: i });
       
       
        }


        const onDeletefilter = async (deletefilterid) => {

            try {
                const response = await fetch(`${apiUrl}/screeningsummary/DeleteSelectedfilter/${deletefilterid}/${UserId}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": `bearer ${jwt}`,
                    },
                });

                if (response.ok) {

                    fetchDDLforFilters();
                    setSelectedfiltername({ id: '', name: '' });
                } else {

                    const results = await response.json();
                    window.alert(results.value);

                }

            } catch (error) {

                console.error('Fetch error:', error);
            }
        }

        const OnFilterNamechange = async (id, name) => {
            try {
                const response = await fetch(`${apiUrl}/screeningsummary/UpdateFiltername/${UserId}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": `bearer ${jwt}`,
                    },
                    body: JSON.stringify({ id: id, filtername: name }),
                });

                if (response.ok) {

                    fetchDDLforFilters();
                    setSelectedfiltername({ id: id, name: name });
                } else {

                    const results = await response.json();
                    window.alert(results.value);
                }
            } catch (error) {

                console.error('Fetch error:', error);
            }

        }

        const handelApplyfilters = (e, i) => {

            handelfilterApply(e, i);

        }
        const handelinputchanges = (e) => {
            var value = e.target.value;
            setEditedName(value);
        }


        const handleEditFilter = (id, name) => {

            OnFilterNamechange(id, name);

        };

        const handleDeleteFilter = (id, name) => {
            onDeletefilter(id);
        };





        if (isddlloaded) {
        
            return (

                <><div className="FilterNameDDL">
                    <Dropdown onClick={e => { setSearchTerm(""); }}>
                        <Dropdown.Toggle variant="light" id="dropdown-split-basic" className="mr-2 saved-filters">
                            <div className="fixed-width">
                                {isEditing ? (
                                    <>
                                        <input
                                            autoFocus="autoFocus"
                                            value={editedName}
                                            onChange={(e) => {
                                                handelinputchanges(e);

                                            }} />
                                    </>
                                ) : (
                                    selectedfiltername.name === '' ? "Select Saved Filters" : selectedfiltername.name
                                )}
                            </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <input type="text" placeholder="Search..." value={searchTerm} className="search-input" onChange={handelSearchInputChange} />
                            {FilteredFilternames.map((options) => <div className="d-flex justify-content-between" key={options.id}>
                                <Dropdown.Item eventKey={options.id} >
                                    <span className="w-100 d-flex align-items-center justify-content-between">
                                        <a onClick={(e) => handelApplyfilters(options.id, options.name)}>
                                            {options.name}
                                        </a>
                                        <div>
                                            {selectedfiltername.id !== '' && selectedfiltername.id == options.id &&
                                                <>
                                                    {isEditing ? (
                                                        <>
                                                            <div style={{ display: 'flex', gap: '15px' }}>
                                                                <button className="buttonhide"
                                                                    onClick={() => {
                                                                        handleEditFilter(selectedfiltername.id, editedName);
                                                                        setIsEditing(false);
                                                                    }}>
                                                                    <BsCheck2Square />
                                                                </button>
                                                                <button className="buttonhide"
                                                                    onClick={() => {
                                                                        setEditedName(originalName);
                                                                        setIsEditing(false);
                                                                    }}>
                                                                    <BsXLg />
                                                                </button>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div style={{ display: 'flex', gap: '15px' }}>
                                                                <button className="buttonhide"
                                                                    onClick={(e) => {
                                                                        setIsEditing(true);
                                                                        setOriginalName(selectedfiltername.name);
                                                                        setEditedName(selectedfiltername.name);
                                                                    }}>
                                                                    <BsFillPencilFill />
                                                                </button>
                                                                <button className="buttonhide"
                                                                    onClick={(e) => handleDeleteFilter(selectedfiltername.id)}>
                                                                    <BsTrashFill />
                                                                </button>
                                                            </div>
                                                        </>
                                                    )}
                                                </>}
                                        </div>
                                    </span>
                                </Dropdown.Item>
                            </div>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                </>
            );
        }
    }

    const Companyfilter = () => {
        const [searchTerm, setSearchTerm] = useState("");


        const handelSearchInputChange = (event) => {
            setSearchTerm(event.target.value);
        };
        const handelCompanychange = (companydb) => {
            handelcompanyswitch(companydb);
            setCompanyNameslist(companydb);
        }

        const handelcompanyswitch = async (comp) => {

            setchangepswdmodal(true);

        }

        const filteredCompanies = ListofCompanies.filter((option) =>
            option.companyName.toLowerCase().includes(searchTerm.toLowerCase())
        );

        if (isddlloaded) {
            return (
                <>
                    <div className="form-group">
                        <div className="CompanyNamesDDL">
                            {coid === '40' && ListofCompanies && (
                                <div>
                                    <Dropdown onSelect={handelCompanychange}>
                                        <Dropdown.Toggle variant="light" id="dropdown-split-basic" className="mr-2 c247_dropdown">
                                            Select Companies
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <input type="text" placeholder="Search companies" value={searchTerm} className="search-input" onChange={handelSearchInputChange} />
                                            {filteredCompanies.map((options) => <Dropdown.Item eventKey={options.companydb} key={options.companydb}>{options.companyName}</Dropdown.Item>
                                            )}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            )}

                        </div>
                    </div>
                    <Passwordconfirmationpswd show={changepswdmodal} onHide={() => { setchangepswdmodal(false); }} />
                </>
            );
        }

    }
    const handleFilterSubmit = async (filtervalues) => {
        setLoading(true);
        setfiltersTorender(filtervalues);

        try {
            const response = await fetch(`${apiUrl}/screeningsummary/GetScreeningSummaryDataWithFilter/${UserId}`, {
                method: 'POST',
                headers: {
                    "Authorization": `bearer ${jwt}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(filtervalues),

            });

            const res = await response.json();
            if (res.message != null) {

                window.alert(res.message);
                setLoading(false);
            }
            else if (res.data) {


                setScreeningData(res.data);
                setLoading(false);
            }
            else {
                window.alert("No results found");
                setLoading(false);
            }

        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    const Topfilter = () => {
        const handleFilterApply = (filtervals) => {
            handleFilterSubmit(filtervals);
        }
        const handleSaveFilter = async (filterName) => {
            setLoading(true);
            setfiltersTorender(savedfilterdata);
            try {

                const response = await fetch(`${apiUrl}/screeningsummary/SaveScreeningSummaryDataWithFilter/${UserId}`, {
                    method: 'POST',
                    headers: {
                        "Authorization": `bearer ${jwt}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ savedfilterdata, filterName }),
                });

                const result = await response.json();
                if (result.message) {
                    window.alert(result.message);

                }
                else if (result.data == null || result.data == undefined) {
                    window.alert("No results found");
                    setLoading(false);
                }
                else {
                    setScreeningData(result.data);
                }
                fetchDDLforFilters();
                setLoading(false);



            } catch (error) {
                console.error(error);
            }

            setShowSaveModal(false);


        };

        const handelsaveFilter = (filterinfo) => {
            setSavedfilterdata(filterinfo);
            setShowSaveModal(true);
        }

        return (
            <div>

                < FilterForm onSubmit={handleFilterApply} onSave={handelsaveFilter} />

                <SaveFilterModal show={showSaveModal} handleClose={() => setShowSaveModal(false)} handleSave={handleSaveFilter} filters={savedfilterdata} />
            </div>
        );
    };
    const handleGlobalFilterChange = (filterValue) => {
        setglobalFilterValue(filterValue);


    };
    const handelSearchfilter = () => {
        return globalFilterValue;

    }


    const [skipPageReset, setSkipPageReset] = React.useState(false)
    const updateMyData = async (rowIndex, columnId, value, dropdownid, staffid, headervalue) => {
        setLoading(true);
        try {
            if (headervalue == "Dep 1" || headervalue == "Dep 2" || headervalue == "Dep 3" || headervalue == "Referrer") {


                const response = await fetch(`${apiUrl}/screeningsummary/UpdateStaffRecords/${UserId}`, {
                    method: 'POST',
                    headers: {
                        "Authorization": `bearer ${jwt}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        staffid: staffid,
                        [headervalue]: value,

                    }),
                });
                if (response.ok) {
                    setLoading(false);

                } else {

                    const results = await response.json();
                    window.alert(results.value);
                    setLoading(false);

                }
            }
            else {
                const response = await fetch(`${apiUrl}/screeningsummary/UpdateStaffRecords/${UserId}`, {
                    method: 'POST',
                    headers: {
                        "Authorization": `bearer ${jwt}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        staffid: staffid,
                        [headervalue]: dropdownid,

                    }),
                });
                if (response.ok) {
                    setLoading(false);

                } else {

                    const results = await response.json();
                    window.alert(results.value);
                    setLoading(false);

                }
            }

        }
        catch (error) {
            console.error('Error during API call:', error);

        }

        // We also turn on the flag to not reset the page
        setSkipPageReset(true);
        setScreeningData(old =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    return {
                        ...old[rowIndex],
                        [columnId]: value,
                    }
                }
                return row

            })

        )
        handelSearchfilter();

    }
    React.useEffect(() => {
        setSkipPageReset(false)
    }, [screeningData])

    const SaveFilterModal = ({ show, handleClose, handleSave, filters }) => {
        const [filterName, setFilterName] = useState('');

        const handleSubmit = (e) => {
            e.preventDefault();

            handleSave(filterName, filters);
            setFilterName('');
        };

        return (
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Save Filter</Modal.Title>
                </Modal.Header>
                <Modal.Body className="save-filter-body">
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="filterName">
                            <Form.Label>Filter Name</Form.Label>
                            <Form.Control type="text" value={filterName} onChange={(e) => setFilterName(e.target.value)} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        Save & Apply
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };



    const handleCloseModal = async (selectedRows) => {

        try {
            if (selectedRows !== undefined && selectedRows && selectedRows.length > 0) {
                setLoading(true);
                const ids = selectedRows.map(row => row.id).join(',');
                const response = await fetch(`${apiUrl}/screeningsummary/ScreeningDataWithStaffid/${UserId}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `bearer ${jwt}`,
                    },
                    body: JSON.stringify(ids),
                });
                const res = await response.json();
                if (res.message != null) {

                    window.alert(res.message);
                    setLoading(false);
                }
                else {


                    setScreeningData(res.data);
                    setLoading(false);
                }

            }
        } catch (error) {
            console.error('Error during API call:', error);

        }
        setshowModalstaffsearch(false);

    };
    const handeladvanceopen = () => {
        setshowModalstaffsearch(true);
    }
    const handleFilterrefresh = (e) => {
        handleFilterSubmit(e);
    }
    return (
        <>

            <LoadingSpinner loading={loading} />
            <div className="ScreeningTable">
                <Table columns={filterColumns(columns)} data={screeningData} updateMyData={updateMyData}
                    skipPageReset={skipPageReset} Topfilter={Topfilter} Companyfilter={Companyfilter} Savedfilter={Savedfilter} Filtervalues={filtersTorender} onGlobalFilterChange={handleGlobalFilterChange} Externalvalue={handelSearchfilter} exportingacess={exportaccess} handeladvanceserch={handeladvanceopen} handelonupdate={handleFilterrefresh} combinedfilter={Forfiltertags} />
            </div>

            <div className="advance-search">


                <StaffSearch showModal={showModalstaffsearch} handleClose={handleCloseModal} />
            </div>
            {isLoadingcompleted && PrescreenmodalShow && (
                <MydModalWithGrid
                    data={PopupPreScreeningdata}
                    show={PrescreenmodalShow}
                    onHide={() => {
                        setPrescreenmodalShow(false);
                    }} />)}
            {

                isLoadingcompleted && ScreeningavailablitymodalShow && (
                    <PreScreeningavailablityWithGrid
                        data={PopupScreeningAvailbalitydata}
                        show={ScreeningavailablitymodalShow}
                        onHide={() => {
                            setScreeningavailablitymodalShow(false);

                        }} />

                )
            }


        </>
    );

}

export default Screeningtable;

